import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const TOS = () => {
    return ( 
        <div className="TOSContainer">
            <Helmet>
                <title>Terms Of Service • TioMusic</title>
                <meta property="og:title" content="Terms Of Service • TioMusic" />
                <meta name="description" content="Terms Of Service for TioMusic." />
                <meta property="og:description" content="Terms Of Service for TioMusic." />
                <meta property="og:url" content="meiibot.xyz/terms-of-service" />
            </Helmet>
            <div className="TOSBubble">
                <div className="TOSTitle">Terms of Service for TioMusic</div>
                <div className="TOSSubTitle">Effective Date: 24-Nov-2024</div>
                <div className="TOSText">
                    <p>
                    </p>
                    <p>
                        Welcome to TioMusic! By using our bot, you agree to the following Terms of Service (ToS). If you do not agree with these terms, please refrain from using TioMusic.
                    </p>
                    <br />
                    <b>Acceptance of Terms:</b>
                    <p>
                        By accessing or using TioMusic, you agree to comply with and be bound by these Terms of Service. These terms apply to all users of the bot, including individuals, groups, or organizations.
                    </p>
                    <p>
                        <b>Description of Service:</b>
                    </p>
                    <p>
                        TioMusic provides music playback and related functionalities through commands sent via a chat platform. Features may include:

                        Playing audio from supported platforms.

                        Managing playlists.

                        Searching for and streaming music.


                        TioMusic is intended for personal and non-commercial use only.
                    </p>
                    <br />
                    <p>
                        <b>User Responsibilities:</b>
                    </p>
                    <p>
                        When using TioMusic, you agree to:

                        Use the bot in accordance with all applicable laws and regulations.

                        Avoid sharing or requesting copyrighted content without proper authorization.

                        Not use the bot for malicious, harmful, or abusive purposes, including spamming, harassment, or violating platform rules.

                        Refrain from reverse-engineering, modifying, or interfering with the bot’s functionality. 
                    </p>
                    <br />
                    <p>
                        <b>Prohibited Uses:</b>
                    </p>
                    <p>
                        You may not use TioMusic to:

                        Upload, stream, or distribute unauthorized or illegal content.

                        Violate the intellectual property rights of others.

                        Circumvent restrictions imposed by third-party platforms or services.

                        Harm the bot, its hosting services, or its users.


                        Violation of these terms may result in temporary or permanent suspension of access to the bot.
                    </p>
                    <br />
                    <p>
                        <b>Limitation of Liability:</b>
                    </p>
                    <p>
                        To the fullest extent permitted by law, TioMusic and its developers shall not be liable for:

Any direct, indirect, incidental, or consequential damages resulting from the use or inability to use the bot.

Loss of data, profits, or business arising from your use of TioMusic.
                    </p>
                    <br />
                    <p>
                        We reserve the right to terminate or restrict your access to TioMusic at our discretion if we determine you have violated these Terms of Service.
                    </p>
                    <br />
                    <p>
                        <b>Contact Us:</b>
                    </p>
                    <p>
                        If you have any questions about these Terms of Service, please contact us: 
                        <Link to="/discord" className='GenericLinkDeco' id="https://discord.com/users/1226523294774071346/"> Azis.exe</Link> on Discord, or
                        through the official <Link to="/discord" className='GenericLinkDeco' id="TOSLink">Support Server</Link> of
                        the Bot. Other ways of support may be provided but aren't guaranteed.
                    </p>
                </div>
            </div>
        </div>
     );
}
 
export default TOS;