import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return ( 
        <div className="HomeContent">
            <Helmet>
                <title>TioMusic</title>
                <meta property="og:title" content="TioMusic" />
                <meta name="description" content="The best free Discord confession bot." />
                <meta property="og:description" content="The best free Discord confession bot." />
                <meta property="og:url" content="tiomusic.xyz" />
            </Helmet>
            <div className="LayoutContainer">
                <div className="InfoBubble">
                    <div className="InfoColLeft">
                        <div className="InfoContent">
                                <div className="AltBoldText">TioMusic</div>
                                <div className="AltText">A Discord bot that aims to provide rich quality music. Supports Deezer, YouTube, Spotify, SoundCloud, and more. Can be used 24/7 in all servers and by all users!</div>
                                <div className="infoButtonContainer">
                                    <Link to="/invite" className='GenericLinkDeco'>
                                        <div className="infoButton" id="InfoMainButton">
                                            Invite me
                                        </div>
                                    </Link>
                                    <Link to="/commands" className='GenericLinkDeco'>
                                        <div className="infoButton" id="InfoAltButton">
                                            Commands
                                        </div>
                                    </Link>
                                </div>
                        </div>
                    </div>
                    <div className="InfoColRight">
                        <div className="InfoImg animated">
                            <img rel="prefetch" src="./img/Logos/TioMusicRoundedPurpleBorder.png" alt="TioMusicLogo" width="150px" height="150px"/>
                        </div>
                    </div>
                </div>
                <div className='InfoDividerHR'><hr /></div>
                <div className='FeatureTitleContainer'>
                    <div className='FeatureTitleText'>Features:</div>
                </div>
                <div className='FeatureBubbleContainer'>
                    <div className="FeatureBubble" id='first'>
                        <div className="FeatureColLeft">
                            <div className='FTextContainer'>
                                <div className='FTitle' id='LongBoi'>Play With Button:</div>
                                <div className='FSubTitle'>Discover and play your favorite music effortlessly with TioMusic. Choose from various platforms like YouTube, Spotify, and Deezer to enjoy your selected songs. With a fast and intuitive search feature, experience unlimited music streaming anytime, anywhere!</div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className='FPic'>
                                <img rel="prefetch" src="./img/Feature1.png" alt="TioMusic Feature1 Screenshot" width="270px" height="130px"/>  
                            </div>
                        </div>
                    </div>
                    <div className="FeatureBubble" id='second'>
                        <div className="FeatureColLeft">
                            <div className='FTextContainer' id='CL'>
                            <div className='FTitle'>Show After Using Button:</div>
                                <div className='FSubTitle'>You can use music filters in Premium mode so that users can use these filters
                                Get Premium for free by joining our server <Link to="/discord" className='GenericLinkDeco' id="PrivacyPLink">Support Server</Link></div>
                            </div>
                        </div>
                        <div className="FeatureColRight">
                            <div className='FPic'>
                                <img rel="prefetch" src="./img/Feature2.png" alt="TioMusic Feature2 Screenshot" width="280px" height="168px"/>  
                            </div>
                        </div>
                    </div>
                </div>
                <div className='EndOfPageSpacer' />
            </div>
        </div>
     );
}

export default Home;
