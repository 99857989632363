import { Link } from 'react-router-dom';

const Footer = () => {
    return ( 
        <div className="footer">
            <div className="footerContentContainer">
                <div className="leftContentContainer">
                    <div className="logoImg">
                        <img rel="prefetch" src="./img/Logos/TioMusicRoundedPurpleBorder.png" alt="TioLogo" width="40px" height="40px"/>
                    </div>  
                    <div className="logoText">
                        Listen to music on Discord for free and simple. 
                    </div>
                    <div className="copyrightContent">
                        Copyright © 2024 TioMusic. All rights reserved
                    </div>
                </div>
                <div className="LCContainer">
                    <div className="linkColumn" id='Space'>
                        <div className="LCTitle">
                            TioMusic
                        </div>
                        <div className="LCLinkContainer">
                            <div className='LCLinkDiv'><Link to="/terms-of-service" id='LC' className='GenericLinkDeco'>Terms Of Service</Link></div>
                            <div className='LCLinkDiv'><Link to="/privacy-policy" id='LC'className='GenericLinkDeco'>Privacy Policy</Link></div>
                            <div className='LCLinkDiv'><Link to="/discord" id='LC'className='GenericLinkDeco'>Support Server</Link></div>
                            <div className='LCLinkDiv'><Link to="https://top.gg/bot/1243777787815399445" id='LC'className='GenericLinkDeco'>Vote</Link></div>
                        </div>
                    </div>
                    <div className="linkColumn">
                        <div className="LCTitle">
                            Developer
                        </div>
                        <div className="LCLinkContainer">
                            <div className='LCLinkDiv'><Link to="https://discord.com/users/1226523294774071346" id='LC' className='GenericLinkDeco'>Discord</Link></div>
                            <div className='LCLinkDiv'><Link to='mailto:azisexe59@gmail.com' id='LC'className='GenericLinkDeco'>Contact</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;
