import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const PrivacyP = () => {
    return ( 
        <div className="PrivacyPContainer">
            <Helmet>
                <title>Privacy Policy for • TioMusic</title>
                <meta property="og:title" content="Privacy Policy for • TioMusic" />
                <meta name="description" content="Privacy Policy for Meii." />
                <meta property="og:description" content="Privacy Policy for TioMusic" />
                <meta property="og:url" content="meiibot.xyz/privacy-policy" />
            </Helmet>
            <div className="PrivacyPBubble">
                <div className="PrivacyPTitle">Privacy Policy</div>
                <div className="PrivacyPSubTitle">Effective Date: 24-Nov-2024</div>
                <div className="PrivacyPText">
                    <p>
                        <p>Welcome to TioMusic! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information while using our bot.</p>
                    </p>
                    <br />
                    <p>
                        <b>Information We Collect:</b>
                    </p>
                    <p>
                        When you use TioMusic, we may collect the following types of information:

User Data: Information such as your username, user ID, and the chat ID where the bot is used.

Usage Data: Logs of commands you send to the bot and how you interact with the service.

Audio Files (if applicable): If you upload or request audio, we may process the files temporarily to fulfill your request. <i>azis.exe</i> on Discord or on our <Link to="/discord" className='GenericLinkDeco' id="PrivacyPLink">Support Server</Link>.
                    </p>
                    <br />
                    <p>
                        <b>How We Use Your Information:</b>
                    </p>
                    <p>
                        Data is stored in a MongoDB database. The database is secured to prevent
                        external access, however no guarantee is provided and the Bot owners
                        We use the information we collect to:

                        Provide and improve the music services.

                        Troubleshoot and enhance the bot’s performance.

                        Monitor the bot for security and abuse prevention.
                    </p>
                    <br />
                    <p>
                        <b>Data Sharing and Disclosure:</b>
                    </p>
                    <p>
                        We value your privacy and do not share your personal information with third parties, except in the following situations:

                        Legal Requirements: If required by law or in response to a valid legal request.

                        Bot Hosting Services: Data may be processed by hosting services necessary to operate TioMusic. 
                    </p>
                    <br />
                    <p>
                        <b>Your Rights:</b>
                    </p>
                    <p>
                        Depending on your location, you may have the right to:

                        Request access to the data we hold about you.

                        Request deletion of your data.

                        Object to the processing of your data.


                        To your Discord server or account. You may submit a request through the Discord <Link to="/discord" className='GenericLinkDeco' id="PrivacyPLink">Support Server</Link> or
                        by adding <i>azis.exe</i> on Discord. You have the right to request the removal of any relevant Data.
                    </p>
                    <br />
                    <p>
                        <b>Third-Party Services:</b>
                    </p>
                    <p>
                        TioMusic may use third-party services (e.g., for streaming or audio processing). These services are governed by their own privacy policies, and we encourage you to review them.
                    </p>
                    <br />
                    <p>
                        <b>Changes to This Privacy Policy:</b>
                    </p>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted here, and the updated version will indicate the effective date. <i>azis.exe</i> on Discord or join the <Link to="/discord" className='GenericLinkDeco' id="PrivacyPLink">Support Server</Link>
                        . For more information check the Discord Terms Of Service.
                    </p>
                </div>
            </div>
        </div>
     );
}
 
export default PrivacyP;