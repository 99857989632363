import { useState } from "react";
import { Helmet } from 'react-helmet-async';

const Commands = () => {
    
    // Filters List
    // all, music, filters, settings, utility, information
    const [filters] = useState([
        {filterName: 'All', filterID:"all", id: 1},
        {filterName: 'Music', filterID:"music", id: 2},
        {filterName: 'Filters', filterID:"filters", id: 3},
        {filterName: 'Settings', filterID:"settings", id: 4},
        {filterName: 'Utility', filterID:"utility", id: 6},
        {filterName: 'Information', filterID:"information", id: 7}
    ])

    // Commands List
    const [commands] = useState([
        {commandName: '.help', commandDesc:"A full list of TioMusic's commands", commandUsage:".help", filter:"utility", id: 1},
        {commandName: '.afk', commandDesc:"Sets a user or member to away from keyboard", commandUsage:".afk [reason]", filter:"utility", id: 2},
        {commandName: '.membercount', commandDesc:"Shows the number of members on the server.", commandUsage:".membercount", filter:"utility", id: 3},
        {commandName: '.autoplay', commandDesc:"Toggles autoplay mode of the player", commandUsage:".autoplay [on/enable | off/disable]", filter:"utility", id: 4},
        {commandName: '.premium', commandDesc:"Toggles premium cateogry for the bot", commandUsage:".premium <activate/revoke/status>", filter:"utility", id: 5},
        {commandName: '.profile', commandDesc:"Shows the profile for a user", commandUsage:".profile [user]", filter:"utility", id: 6},
        {commandName: '.serverinfo', commandDesc:"Shows the serverinfo", commandUsage:".serverinfo", filter:"utility", id: 7},
        {commandName: '.setup', commandDesc:"Configures Setup for the bot with dj/djrole/playtype etc", commandUsage:".setup <player/playtype/music-system>", filter:"utility", id: 8},
        {commandName: '.userinfo', commandDesc:"Display a users information", commandUsage:".userinfo [user]", filter:"utility", id: 9},
        {commandName: '.clearqueue', commandDesc:"Cleares the queue of the player", commandUsage:".clearqueue", filter:"music", id: 10},
        {commandName: '.play', commandDesc:"Plays the music by adding some songs to the queue", commandUsage:".play [query/url]", filter:"music", id: 11},
        {commandName: '.connect', commandDesc:"Connects the bot to a voice channel", commandUsage:".connect", filter:"music", id: 12},
        {commandName: '.lyrics', commandDesc:"Shows the lyrics of the current playing song", commandUsage:".lyrics [url/song name]", filter:"music", id: 13},
        {commandName: '.disconnect', commandDesc:"Disconnects the bot from a voice channel", commandUsage:".disconnect", filter:"music", id: 14},
        {commandName: '.loop', commandDesc:"Sets the loop mode of the player", commandUsage:".loop <off/track/queue>", filter:"music", id: 15},
        {commandName: '.move', commandDesc:"Jumps to the particular track in the queue", commandUsage:".move [number]", filter:"music", id: 16},
        {commandName: '.nowplaying', commandDesc:"Shows the current playing song", commandUsage:".nowplaying", filter:"music", id: 17},
        {commandName: '.queue', commandDesc:"Displays the queue of the player", commandUsage:".queue", filter:"music", id: 18},
        {commandName: '.previous', commandDesc:"Plays the previous track of the queue and skips the current", commandUsage:".previous", filter:"music", id: 19},
        {commandName: '.pause', commandDesc:"Pauses the current track", commandUsage:".pause", filter:"music", id: 20},
        {commandName: '.remove', commandDesc:"Removes a track from the queue using position", commandUsage:".remove [position]", filter:"music", id: 21},
        {commandName: '.replay', commandDesc:"Restarts playing the current track", commandUsage:".replay", filter:"music", id: 22},
        {commandName: '.resume', commandDesc:"Resumes the track if paused", commandUsage:".resume", filter:"music", id: 23},
        {commandName: '.seek', commandDesc:"Seeks the player to the provided timestamp", commandUsage:".seek <time>\nexample: seek 1m", filter:"music", id: 24},
        {commandName: '.shuffle', commandDesc:"Shuffles the queue of the player", commandUsage:".shuffle", filter:"music", id: 25},
        {commandName: '.skip', commandDesc:"Skips the current track of the player", commandUsage:".skip", filter:"music", id: 26},
        {commandName: '.stop', commandDesc:"Stops the player and resetts the queue", commandUsage:".stop", filter:"music", id: 27},
        {commandName: '.volume', commandDesc:"Controls the volume of the player", commandUsage:".volume [number]", filter:"music", id: 28},
        {commandName: '.8d', commandDesc:"Toggles 8D filter for the player", commandUsage:".8d", filter:"filters", id: 29},
        {commandName: '.bassboost', commandDesc:"Toggles BassBoost filter to the player", commandUsage:".bassboost", filter:"filters", id: 30},
        {commandName: '.china', commandDesc:"Toggles China filter to the player", commandUsage:".china", filter:"filters", id: 31},
        {commandName: '.chipmunk', commandDesc:"Toggles Chipmunk filter to the player", commandUsage:".hipmunk", filter:"filters", id: 32},
        {commandName: '.daycore', commandDesc:"Toggles Daycore filter to the player", commandUsage:".daycore", filter:"filters", id: 33},
        {commandName: '.distortion', commandDesc:"Toggles distortion filter to the player", commandUsage:".distortion", filter:"filters", id: 34},
        {commandName: '.earrape', commandDesc:"Toggles earrape filter to the player", commandUsage:".earrape", filter:"filters", id: 35},
        {commandName: '.karaoke', commandDesc:"Toggles karaoke filter to the player", commandUsage:".karaoke", filter:"filters", id: 36},
        {commandName: '.nightcore', commandDesc:"Toggles nightcore filter to the player", commandUsage:".nightcore", filter:"filters", id: 37},
        {commandName: '.pop', commandDesc:"Toggles pop filter to the player", commandUsage:".pop", filter:"filters", id: 38},
        {commandName: '.soft', commandDesc:"Toggles soft filter to the player", commandUsage:".soft", filter:"filters", id: 39},
        {commandName: '.treblebass', commandDesc:"Toggles treblebass filter to the player", commandUsage:".treblebass", filter:"filters", id: 40},
        {commandName: '.tremolo', commandDesc:"Togglss tremolo filter to the player", commandUsage:".tremolo", filter:"filters", id: 41},
        {commandName: '.vaporwave', commandDesc:"Togglss vaporwave filter to the player", commandUsage:".vaporwave", filter:"filters", id: 42},
        {commandName: '.vibrato', commandDesc:"Togglss vibrato filter to the player", commandUsage:".vibrato", filter:"filters", id: 43},
        {commandName: '.247', commandDesc:"Toggles 24/7 mode of the player", commandUsage:".24/7 [on/enable | off/disable]", filter:"settings", id: 44},
        {commandName: '.set-prefix', commandDesc:"Helps getting a custom-prefix for a guild", commandUsage:".set-prefix [prefix]", filter:"settings", id: 45},
        {commandName: '.ping', commandDesc:"Informs you about the bot's latency", commandUsage:".ping", filter:"information", id: 46},
        {commandName: '.about', commandDesc:"Provides you with the information of the bot", commandUsage:".about", filter:"information", id: 47},
        {commandName: '.invite', commandDesc:"Provides you with the Invite link of the bot", commandUsage:".invite", filter:"information", id: 48},
        {commandName: '.vote', commandDesc:"Provides you bot vote link", commandUsage:".vote", filter:"information", id: 49},
        {commandName: '.support', commandDesc:"Provides you with the server link for the bot's support", commandUsage:".support", filter:"information", id: 50},
        {commandName: '.stats', commandDesc:"Informs you about the current statitics of the bot", commandUsage:".stats", filter:"information", id: 51},
        {commandName: '.uptime', commandDesc:"Informs you about the bot's latency", commandUsage:".uptime", filter:"information", id: 52}
    ])

    //Button Handlers
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('all');

    const handleClickCommand = (commandId) => {
        setSelectedCommand(commandId === selectedCommand ? null : commandId);
    };
    const handleClickFilter = (filter) => {
        setSelectedFilter(filter === selectedFilter ? 'all' : filter);
    };

    return ( 
        <div className="CommandsContent">
            <Helmet>
                <title>Commands • TioMusic</title>
                <meta property="og:title" content="Commands • TioMusic" />
                <meta name="description" content="The commands list for Meii." />
                <meta property="og:description" content="The commands list for Meii." />
                <meta property="og:url" content="meiibot.xyz/commands" />
            </Helmet>
            <div className="CommandsLayoutContainer">
                <div className="CommandBoxContainer">
                <div className="CommandAltAnimationHandler"> 
                    <div className="CommandsSectionTitle">Commands</div>
                    <div className="CommandsFilterContainer">
                    {filters.map((filter) => (
                        <div className="FilterButton" id={`${filter.filterID === selectedFilter ? "selected" : "" }`} onClick={() => handleClickFilter(filter.filterID)} key={filter.id}>
                            <div className="FilterTitle">{filter.filterName}</div>
                        </div>
                    ))}
                </div>
                </div>
                <div className="CommandListContainer">
                    {commands.map((command, index) => (
                        <div className="CommandBubbleAnimationHandler" style={{ animationDelay: `${25 * index}ms` }} key={command.id}>
                            <div className={`${command.id === selectedCommand ? "CommandBubbleExpanded" : "CommandBubble" }`} id={`${command.filter === selectedFilter || 'all' === selectedFilter ? "" : "inactive" }`} key={command.id} onClick={() => handleClickCommand(command.id)}>
                                <div className="CommandBubbleInsideContainer">
                                    <div className="CommandBubbleNonExpandedContainer">
                                        <div className="CommandBubbleTitleContainer">
                                            <div className="CommandBubbleTitle">{command.commandName}</div>
                                            <div className="CommandBubbleExpandArrow" id={`menuButton_${command.id === selectedCommand ? 'active' : 'inactive'}`}>
                                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="dropdownIcon" id={`dropdownIcon_${command.id === selectedCommand ? 'active' : 'inactive'}`}><path d="M7 14.5l5-5 5 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleDesc">{command.commandDesc}</div>
                                    </div>
                                    <div className="CommandBubbleExpandedContainer" id={`${command.id === selectedCommand ? "" : "inactive" }`} >
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Usage</div>
                                            <div className="CommandBubbleFieldSubTitleContainer" id="usage">
                                                <div className="CommandBubbleFieldSubTitle">
                                                    {command.commandUsage} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="CommandBubbleField">
                                            <div className="CommandBubbleFieldTitle">Category</div>
                                            <div className="CommandBubbleFieldSubTitle">{command.filter}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    ))}
                </div>    
            </div>
            </div>
        </div>
     );
}
 
export default Commands;